import { OrderListResponse } from "./types";
import { getParamStringFromObject } from "~/utils/helpers";
import { fetchAPI2 } from "../fetchAPI2";
import { simulateApiError } from "../errors";

export type ListOrderFilters = {
  /**
   * Formatted as YYYY-MM-DD
   */
  from?: string;
  days?: number;
};

export const _listOrders = async (
  filters?: Partial<ListOrderFilters>
): Promise<OrderListResponse> => {
  const today = new Date().toISOString().split("T")[0];
  const defaultFilters: ListOrderFilters = {
    from: today,
    days: 365,
  };
  const merged: ListOrderFilters = { ...defaultFilters, ...filters };

  const params = getParamStringFromObject(merged);

  // await simulateApiError({path: `/orders${params}`})

  const data = await fetchAPI2<OrderListResponse>(`/orders${params}`);

  return data;
};