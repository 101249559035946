import { query, createAsync } from "@solidjs/router";
import { _listOrders, type ListOrderFilters } from "../roma-api/orders";

export const getOrders = query(
  async (filters?: ListOrderFilters) => _listOrders(filters),
  "orders"
);

export const useOrders = (
  filters: () => ListOrderFilters = () => ({}),
  options: { deferStream: boolean } = { deferStream: false }
) => createAsync(() => getOrders(filters()), options);
