import { query, createAsync } from "@solidjs/router";
import { _retrieveInvoiceDownload } from "../roma-api/invoices";

export const getInvoicePDF = async (id: string) => {
  return await _retrieveInvoiceDownload(id);
};

// TODO - cache blob?

// export const getInvoicePDF = query((id: string)=> _retrieveInvoiceDownload(id), "invoice-pdfs");
