
import { fetchAPI2 } from "../fetchAPI2";
import { simulateApiError } from "../errors";

export const _retrieveOrderDownload = async (id: string): Promise<Blob> => {
  // TODO This needs testing

  // await simulateApiError({path: `/orders/${id}/download`})

  const data = await fetchAPI2<Blob>(`/orders/${id}/download`);

  return data;
};
